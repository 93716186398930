<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="Receive Detail">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <div class="devider" />
              <b-form-group>
                <label for="email">Purchase Order Number :</label>
                <input
                  v-model="purchaseOrderNumber"
                  type="text"
                  name="Number"
                  class="form-control"
                  readonly
                />
              </b-form-group>
              <b-form-group>
                <label for="email">Purchase Order Purchasing Receive Number :</label>
                <input
                  v-model="purchaseOrderPurchasingReceiveNumber"
                  type="text"
                  class="form-control"
                  readonly
                />
              </b-form-group>

            <b-form-group>
              <label for="no_invoice">Items :</label>
            </b-form-group>
            <b-form-group>
              <br />
              <div
              >
                <b-row>
                  <b-col cols="6" class="text-center font-weight-bold">
                    Item name
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                    Unit Name
                  </b-col>
                  <b-col cols="3" class="text-center font-weight-bold">
                    QTY
                  </b-col>
                </b-row>
                <p />
                <b-row
                  v-for="item in items"
                  :key="item.purchase_order_warehouse_receive_detail_item_id"
                >
                  <br />
                  <b-col cols="6" class="text-center">
                    <b-list-group>
                      <b-list-group-item href="#">
                        {{ item.item.item_name }} ({{ item.item.item_code }})
                      </b-list-group-item>
                    </b-list-group>
                  </b-col>
                  <b-col cols="3" class="text-center">
                    <b-list-group-item href="#">
                      {{
                        item.item.unit
                          ? capitalizeWords(item.item.unit.unit_name)
                          : "-"
                      }}
                    </b-list-group-item>
                  </b-col>
                  <b-col cols="3" class="text-center">
                    <b-form-input
                      v-model="item.received"
                      class="text-center"
                      readonly
                    />
                  </b-col>
                  <b-col cols="12">
                    <br />
                  </b-col>
                </b-row>
              </div>
            </b-form-group>
                <b-form-group>
                <label for="no_invoice">Status :</label>
                <vue-horizontal-timeline :items="statusItems" />
                </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
  } from "bootstrap-vue"
  import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
  import axios from "axios"
  import moment from "moment"
  import Ripple from "vue-ripple-directive"
  import { VueHorizontalTimeline } from "vue-horizontal-timeline"
  import { ref } from "@vue/composition-api"

  const VersionCheck = ""
  const items = []
  const chosenItem = ref(3)
  const chosens = []
  const statusItems = []
  const purchaseOrderNumber = ""
  const purchaseOrderPurchasingReceiveNumber = ""
  const assetTo = ""
  const assetCc = ""
  const assetReason = ""
  const assetQtyRequested = 0
  const assetStatus = ""
  const assetId = ""
  const assetNumber = ""
  const assetType = ""
  const materialrequestIsAcceptedBySupervisor = 0
  const materialrequestIsAcceptedByWarehouse = 0
  const materialrequestIsReceived = 0
  const apiUrl = process.env.VUE_APP_API_URL
  export default {
    components: {
      VueHorizontalTimeline,
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        purchaseOrderNumber,
        purchaseOrderPurchasingReceiveNumber,
        statusItems,
        VersionCheck,
        items,
        chosenItem,
        chosens,
        assetTo,
        assetCc,
        assetReason,
        assetQtyRequested,
        assetStatus,
        assetId,
        assetNumber,
        materialrequestIsAcceptedBySupervisor,
        materialrequestIsAcceptedByWarehouse,
        materialrequestIsReceived,
        assetType,
        apiUrl,
      }
    },
    mounted() {
      this.getDetail()
    },
    methods: {
      getDetail() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const item = JSON.parse(localStorage.getItem("AssetReceiveDetail"))

        axios.get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_PURCHASE_ORDER_RECEIVE_ASSET_CHECK}${item.purchase_order_purchasing_receive.purchase_order_purchasing_receive_id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const temp = []
              response.data.data.forEach(elem => {
                const tempNum = parseInt(elem.purchase_order_purchasing_receive_detail_item_received)
                temp.push({
                    itemId: elem.item.item_id,
                    label: `${elem.item.item_name} (${elem.item.item_code}) (QTY : ${elem.purchase_order_purchasing_receive_detail_item_requested})`,
                    unit: elem.item.unit ? elem.item.unit.unit_name : '-',
                    name: elem.item.item_name,
                    value: elem.purchase_order_purchasing_receive_detail_id,
                    asset_id: '',
                    received: elem.purchase_order_purchasing_receive_detail_item_received,
                    max: tempNum,
                    item: elem.item,
                    checked: false,
                    shelves: elem.shelves,

                    id: temp.length + 1,
                  })
              })
              this.items = temp
        this.modalDetailShow = true
        this.purchaseOrderNumber = item.purchase_order_purchasing_receive.purchase_order.purchase_order_number
        this.purchaseOrderPurchasingReceiveNumber = item.purchase_order_purchasing_receive.purchase_order_purchasing_receive_receiving_number
        const obj1 = {
          title: "Accounting",
          content:
            item.purchase_order_warehouse_receive_confirmed_by_accounting !== null
              ? `Purchase Order Assets Has Been Confirmed by ${
                  item.accounting.user_fullname
                } at ${this.dateSimple(
                  item.purchase_order_warehouse_receive_confirmed_by_accounting_time,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Order Assets Has Been  Has not been Accepted By Accounting`,
          stepCssClass:
            item.purchase_order_warehouse_receive_confirmed_by_accounting !== null
              ? "has-step-green"
              : "has-step-red",
        }
        const obj2 = {
          title: "Laboratory Manager",
          content:
            item.purchase_order_warehouse_receive_confirmed_by_lab_manager !== null
              ? `Purchase Order Assets Has Been Confirmed by ${
                  item.lab_manager.user_fullname
                } at ${this.dateSimple(
                  item.purchase_order_warehouse_receive_confirmed_by_lab_manager_time,
                  "YYYY-MM-DD",
                )}`
              : `Purchase Order Assets Has Been  Has not been Accepted By Laboratory Manager`,
          stepCssClass:
            item.purchase_order_warehouse_receive_confirmed_by_lab_manager !== null
              ? "has-step-green"
              : "has-step-red",
        }
        this.statusItems = []
        this.statusItems.push(obj1)
        this.statusItems.push(obj2)
        }
    })
      },
      print() {
        const userToken = this.$cookies.get("userToken")
        const headers = {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        }
        const id = this.assetId
        axios
          .get(
            `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_DOWNLOAD_MATERIAL_REQUEST}${id}`,
            { headers },
          )
          .then(response => {
            if (response.data.success === true) {
              const url = `${process.env.VUE_APP_API_URL}temp/${response.data.data}`
              window.open(url, "_blank")
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Download Data failed",
                  icon: "CheckIcon",
                  variant: "danger",
                },
              })
            }
          })
      },
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
            if (elem.value === val.value) {
              same = true
            }
          })
          if (same === false) {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })
            this.chosens.push(item)
          }
        } else {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })

          this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => obj.value !== val)
      },
      dateFormat(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      dateSimple(value, format = "YYYY-MM-DD") {
        let dateRet = ""
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else {
          dateRet = null
        }
        return dateRet
      },
      previous() {
        this.$router.push({ name: "apps-assets-receive-list" })
      },
      getFullUrl(picturePath) {
        return `${this.apiUrl}/upload/${picturePath}`
      },
      capitalizeWords(str) {
        // Split the string into words using spaces as the separator
        const words = str.split(" ")
        // Capitalize the first letter of each word and join them back together
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        // Join the capitalized words into a single string with spaces
        return capitalizedWords.join(" ")
      },
    },
  }
  </script>